import { useState } from "react";
import Box from "../../components/Box";
import Pagination from "../../components/Pagination";
import MainLayout from "../../components/layouts/MainLayout";
import ModalAccountTechnician from "../../components/technician/ModalAccountTechnician";
import ModalCreateTechnician from "../../components/technician/ModalCreateTechnician";
import ModalDeleteTechnician from "../../components/technician/ModalDeleteTechnician";
import ModalUpdateGroupTechnician from "../../components/technician/ModalUpdateGroupTechnician";
import ModalUpdateTechnician from "../../components/technician/ModalUpdateTechnician";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import { useTechnicianListHooks } from "../../utils/TechniciansUtils";
import ModalEditPasswordTechnician from "../../components/technician/ModalEditPasswordTechnician";
import ModalDetailTechnician from "../../components/technician/ModalDetailTechnician";

export default function TechnicianPage() {
  let { hasAccess } = useControlPermission();
  const { projects } = useGroupListHooks();

  let [filter, setFilter] = useState({
    name: "",
    role: "",
    page_size: 10,
    page: 1,
    kota_kab: "",
    province: "",
    project_id: "",
  });
  const regionFilter = useCityRegionHooks();
  let [formFilter, setFormFilter] = useState({ name: "", role: "", project_id: "" });
  let [selectedId, setSelectedId] = useState(null);
  const [selected, setSelected] = useState({});
  let [account, setAccount] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [showModalCreate, setShowModalCreate] = useState(false);
  let [showModalUpdate, setShowModalUpdate] = useState(false);
  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalDelete, setShowModalDelete] = useState(false);
  let [showModalAccount, setShowModalAccount] = useState(false);
  let [showModalChangePass, setShowModalChangePass] = useState(false);
  let [showModalProject, setShowModalProject] = useState(false);

  let technicianData = useTechnicianListHooks({ filter });

  const onSubmitFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
      kota_kab: regionFilter.city,
      province: regionFilter.province,
    });
  };

  const onChangeProvinceFilter = (value) => {
    regionFilter.setProvince(value);
    regionFilter.setCity("");
  };

  const onResetFilter = () => {
    setFilter({
      name: "",
      role: "",
      project_id: "",
      page_size: 10,
      page: 1,
      kota_kab: "",
      province: "",
    });
    regionFilter.resetRegion();
    setFormFilter({ name: "", role: "", project_id: "" });
  };

  const onSuccess = (show, message, account = null) => {
    if (account) {
      setAccount(account);
      setShowModalAccount(true);
    }

    technicianData.getTechnicians(filter);
    setAlert({ show, message, type: "success" });
    if (account) {
      setShowModalAccount(true);
    }
  };

  const onSuccessChangePass = (message) => {
    technicianData.getTechnicians(filter);
    setAlert({ show: true, message, type: "success" });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const toggleOpenModalUpdate = (id = "") => {
    setSelectedId(id);
    setShowModalUpdate(!showModalUpdate);
  };

  const toggleOpenModalDetail = (id = "") => {
    setSelectedId(id);
    setShowModalDetail(!showModalDetail);
  };

  const toggleOpenModalDelete = (id = "") => {
    setSelectedId(id);
    setShowModalDelete(!showModalDelete);
  };

  const toggleOpenModalProject = (id = "") => {
    setSelectedId(id);

    if (showModalProject) {
      technicianData.getTechnicians(filter);
    }

    setShowModalProject(!showModalProject);
  };

  const toggleModalChangePass = (account = {}) => {
    setSelected(account);
    setShowModalChangePass(!showModalChangePass);
  };

  return (
    <MainLayout resource_key="manage-technician">
      <div className="container-fluid">
        <div className="mb-4">
          <div className="row">
            <div className="col">
              <h1 className="h3 mb-0 text-gray-800">Manajemen Teknisi</h1>
            </div>
            <div className="col-auto">
              {hasAccess("create-technician") && (
                <button className="btn btn-primary" onClick={() => setShowModalCreate(true)}>
                  <i className="fa fa-plus"></i> Tambah Teknisi
                </button>
              )}
            </div>
          </div>
        </div>
        <Box title="Teknisi" loading={technicianData.loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <label htmlFor="roleFilter">Role</label>
                <select
                  className="form-control"
                  id="roleFilter"
                  required
                  value={formFilter.role}
                  onChange={(event) => setFormFilter({ ...formFilter, role: event.target.value })}
                >
                  <option value="">SEMUA ROLE</option>
                  <option value="technician">TECHNICIAN</option>
                  <option value="helper">HELPER</option>
                </select>
              </div>
              <div className="col-12 col-md-2">
                <label htmlFor="namefilter">Nama</label>
                <input
                  type="text"
                  className="form-control"
                  id="namefilter"
                  value={formFilter.name}
                  onChange={(event) => setFormFilter({ ...formFilter, name: event.target.value })}
                />
              </div>
              <div className="col-12 col-md-2">
                <label>Provinsi</label>
                <select
                  className="form-control"
                  value={regionFilter.province}
                  onChange={(event) => onChangeProvinceFilter(event.target.value)}
                >
                  <option value="">-- Semua Provinsi --</option>
                  {regionFilter.provinces.map((province, index) => (
                    <option value={province.province} key={index}>
                      {province.province}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <label>Kabupaten</label>
                <select
                  className="form-control"
                  value={regionFilter.city}
                  onChange={(event) => regionFilter.setCity(event.target.value)}
                >
                  <option value="">-- Semua Kabupaten --</option>
                  {regionFilter.cities.map((city, index) => (
                    <option value={city.kota_kab} key={index}>
                      {city.kota_kab}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="projectFilter">Grup/Project</label>
                  <select
                    className="form-control"
                    id="projectFilter"
                    value={formFilter.project_id}
                    onChange={(event) =>
                      setFormFilter({ ...formFilter, project_id: event.target.value })
                    }
                  >
                    <option value="">SEMUA</option>
                    {projects.map((project, index) => (
                      <option value={project.id} key={index}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <label className="d-block">&nbsp;</label>
                <button className="btn btn-primary" onClick={() => onSubmitFilter()}>
                  Filter
                </button>
                <button className="btn btn-default" onClick={() => onResetFilter()}>
                  Reset
                </button>
              </div>
            </div>
          </div>

          {technicianData.errorMsg && (
            <div className="alert alert-danger">{technicianData.errorMsg}</div>
          )}
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>Foto</th>
                  <th>No. Teknisi</th>
                  <th>Nama</th>
                  <th>Role</th>
                  <th>Nomor KTP</th>
                  <th>Email</th>
                  <th>Telepon</th>
                  <th>Area</th>
                  <th>Grup/Project</th>
                  <th>Username</th>
                </tr>
              </thead>
              <tbody>
                {technicianData.technicians.length === 0 && (
                  <tr>
                    <td colSpan={11} className="text-center">
                      Tidak Ada Data
                    </td>
                  </tr>
                )}
                {technicianData.technicians.map((technician, index) => (
                  <tr key={index}>
                    <td>
                      <button
                        className="btn text-nowrap btn-block btn-sm btn-primary"
                        onClick={() => toggleOpenModalDetail(technician.id)}
                      >
                        <i className="fa fa-eye"></i> Lihat Detail
                      </button>
                      {hasAccess("reset-technician-password") && (
                        <button
                          className="btn text-nowrap btn-block btn-sm btn-warning"
                          onClick={() => toggleModalChangePass(technician)}
                        >
                          <i className="fa fa-key"></i> Ubah Password
                        </button>
                      )}

                      {hasAccess("update-technician") && (
                        <button
                          className="btn text-no-wrap btn-block btn-sm btn-info"
                          onClick={() => toggleOpenModalUpdate(technician.id)}
                        >
                          <i className="fa fa-pencil-alt"></i> Edit
                        </button>
                      )}
                      {hasAccess("manage-technician-project") && (
                        <button
                          className="btn text-nowrap btn-block btn-sm btn-custom-orange"
                          onClick={() => toggleOpenModalProject(technician.id)}
                        >
                          <i className="fa fa-map-pin"></i> Kelola Grup
                        </button>
                      )}
                      {hasAccess("delete-technician") && (
                        <button
                          className="btn text-nowrap btn-block btn-sm btn-danger"
                          onClick={() => toggleOpenModalDelete(technician.id)}
                        >
                          <i className="fa fa-trash"></i> Delete
                        </button>
                      )}
                    </td>
                    <td className="text-center">
                      {technician.image_url ? (
                        <img
                          src={technician.image_url}
                          alt={technician.name}
                          style={{ maxWidth: "100px" }}
                        />
                      ) : (
                        " - "
                      )}
                    </td>
                    <td>
                      {technician.technician_detail &&
                        technician.technician_detail.technician_number}
                    </td>
                    <td>{technician.name}</td>
                    <td>
                      {technician.roles.length === 0 && "-"}
                      <ul className="px-3">
                        {technician.roles.map((role, index) => (
                          <li key={index}>{role.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td>{technician.id_card_number}</td>
                    <td>{technician.email}</td>
                    <td>{technician.phone_number}</td>
                    <td>
                      {technician.addresses
                        ? `${technician.addresses.kota_kab} - ${technician.addresses.province}`
                        : "-"}
                    </td>
                    <td className="text-nowrap">
                      {technician.projects.length === 0 && "-"}
                      <ul className="px-3">
                        {technician.projects.map((project, index) => (
                          <li key={index}>{project.name}</li>
                        ))}
                      </ul>
                    </td>
                    <td>{technician.username}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {technicianData.meta.count > technicianData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(technicianData.meta.count / technicianData.meta.page_size)}
              selectedpage={technicianData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(technicianData.meta.page + 1)}
              prev={() => gotoPage(technicianData.meta.page - 1)}
            />
          )}
        </Box>
      </div>

      {/* Modal Create */}
      <ModalCreateTechnician
        show={showModalCreate}
        onClose={setShowModalCreate}
        onSuccess={onSuccess}
      />
      {/* Modal Detail */}
      <ModalDetailTechnician
        show={showModalDetail}
        onClose={toggleOpenModalDetail}
        id={showModalDetail ? selectedId : ""}
      />
      {/* Modal Update */}
      <ModalUpdateTechnician
        show={showModalUpdate}
        onClose={toggleOpenModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onSuccess={onSuccess}
      />
      {/* Modaal Delete */}
      <ModalDeleteTechnician
        show={showModalDelete}
        onClose={toggleOpenModalDelete}
        id={showModalDelete ? selectedId : ""}
        onSuccess={onSuccess}
      />
      {/* Modal Update Project */}
      <ModalUpdateGroupTechnician
        show={showModalProject}
        onClose={toggleOpenModalProject}
        id={showModalProject ? selectedId : null}
      />
      {/* Modal Detail */}
      <ModalAccountTechnician
        show={showModalAccount}
        onClose={() => setShowModalAccount(!showModalAccount)}
        account={account}
      />
      {/* Modal Password */}
      <ModalEditPasswordTechnician
        show={showModalChangePass}
        setShow={toggleModalChangePass}
        account={selected}
        onSuccess={onSuccessChangePass}
      ></ModalEditPasswordTechnician>
    </MainLayout>
  );
}
