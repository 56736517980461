import { useEffect, useMemo, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import Toggle from "react-toggle";
import { useActivityCode, useActivityType } from "../../../utils/customer/ActivityUtils";
import {
  useCustomerContext,
  useDetailActivityHooks,
} from "../../../utils/customer/CustomerRelationUtils";
import {
  createActivityByCustomerId,
  updateSingleActivityByCustomerId,
} from "../../../api/customer";
import AlertBox from "../../AlertBox";
import { ROLE_ASSIGN_TICKET } from "../../../tools/tickettools";

export function SelectActivityType({ value, changeValue, disabled, ...rest }) {
  const { types, loading } = useActivityType();

  return (
    <select
      className="form-control"
      value={value}
      onChange={(event) => changeValue(event.target.value)}
      disabled={disabled || loading}
      required
      {...rest}
    >
      <option value="">-- Pilih tipe aktivitas --</option>
      {types.map((activityType) => (
        <option value={activityType.id} key={activityType.id}>
          {activityType.name}
        </option>
      ))}
    </select>
  );
}

export function SelectActivityCode({ value, changeValue, disabled, ...rest }) {
  const { codes, loading } = useActivityCode();

  return (
    <select
      className="form-control"
      value={value}
      onChange={(event) => changeValue(event.target.value)}
      disabled={disabled || loading}
      required
      {...rest}
    >
      <option value="">-- Pilih kode aktivitas --</option>
      {codes.map((code) => (
        <option value={code.id} key={code.id}>
          {code.code} - {code.description}
        </option>
      ))}
    </select>
  );
}

function SearchableActivityCode({ value, changeValue, initialOptions, disabled }) {
  const { codes, loading } = useActivityCode();
  const dropdownRef = useRef(null);

  const [searchTerm, setSearchTerm] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [isEditMode, setEditMode] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);

  let filteredOptions = [];

  if (!loading) {
    filteredOptions = codes.filter((item) => {
      const searchLower = searchTerm.toLowerCase();
      return (
        item.code.toLowerCase().includes(searchLower) ||
        item.description.toLowerCase().includes(searchLower)
      );
    });
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        if (selectedOption !== null) {
          setEditMode(false);
        }
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => document.removeEventListener("mousedown", handleClickOutside);
  }, [selectedOption]);

  useEffect(() => {
    if (initialOptions) {
      setEditMode(false);
      setSelectedOption({ ...initialOptions });
    }

    // console.log("happen in initial options");
  }, [initialOptions]);

  const onChooseItem = (selected) => {
    setSelectedOption(selected);
    setEditMode(false);
    changeValue(selected.id);
    setIsOpen(false);
    setSearchTerm("");
  };

  return (
    <div className="search-dropdown" ref={dropdownRef}>
      {selectedOption && !isEditMode ? (
        <div
          className="selected-item"
          role="searchbox"
          onClick={() => {
            setEditMode(true);
            setIsOpen(true);
          }}
        >
          {selectedOption.code} - {selectedOption.description}
        </div>
      ) : null}

      {isEditMode ? (
        <input
          type="text"
          className="form-control"
          placeholder="Pilih / cari kode ..."
          value={searchTerm}
          onChange={(event) => setSearchTerm(event.target.value)}
          disabled={disabled || loading}
          onClick={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
          onFocus={(e) => {
            e.stopPropagation();
            setIsOpen(true);
          }}
        />
      ) : null}

      {isOpen ? (
        <div className="item-list">
          {filteredOptions.map((code) => (
            <div
              role="listitem"
              className="item"
              value={code.id}
              key={code.id}
              onClick={() => onChooseItem(code)}
            >
              {code.code} - {code.description}
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
}
export function ModalCreateActivities({ show, onClose, onSuccess }) {
  const { customer } = useCustomerContext();

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [form, setForm] = useState({
    note: "",
    solution: "",
    activity_code_id: "",
    activity_type_id: "",
    resolved: "false",
    ticketOpt: false,
    priority: "low",
    type: "",
    role: "",
    description: "",
    name: "",
    phone_number: "",
    address: "",
  });

  const handleClose = () => {
    setForm({
      note: "",
      solution: "",
      activity_code_id: "",
      activity_type_id: "",
      resolved: "false",
      ticketOpt: false,
      priority: "low",
      type: "",
      role: "",
      description: "",
      name: "",
      phone_number: "",
      address: "",
    });
    setAlert({ show: false, message: "", type: "" });
    onClose();
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      note: form.note,
      activity_code_id: form.activity_code_id,
      activity_type_id: form.activity_type_id,
      resolved: form.resolved,
      solution: form.resolved === "true" ? form.solution : "",
    };

    if (form.ticketOpt) {
      formData.ticket = {
        priority: form.priority,
        type: form.type,
        role: form.role,
        description: form.description,
        name: form.name,
        phone_number: form.phone_number,
        address: form.address,
      };
    }
    sendNewActivity(formData);
  };

  const sendNewActivity = async (form) => {
    setLoading(true);
    try {
      let response = await createActivityByCustomerId(customer.id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const onToggleTicketOpt = (event) => {
    if (event.target.checked) {
      setForm({
        ...form,
        ticketOpt: true,
        name: customer.name || "",
        phone_number: customer.phone_number || "",
        address:
          customer.installation_address.address +
          " RT " +
          customer.installation_address.rt +
          "/RW " +
          customer.installation_address.rw +
          ", " +
          customer.installation_address.addresses.kelurahan +
          ", " +
          customer.installation_address.addresses.kecamatan +
          ", " +
          customer.installation_address.addresses.kota_kab +
          ", " +
          customer.installation_address.addresses.province +
          ", " +
          customer.installation_address.addresses.postal_code,
      });
    } else {
      setForm({ ...form, ticketOpt: false });
    }
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Buat Aktivitas Baru</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={(event) => submitData(event)}>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>Tipe</label>
            <SelectActivityType
              value={form.activity_type_id}
              changeValue={(value) => setForm({ ...form, activity_type_id: value })}
            />
          </div>
          <div className="form-group">
            <label>Kode</label>
            <SearchableActivityCode
              value={form.activity_code_id}
              changeValue={(value) => setForm({ ...form, activity_code_id: value })}
            />
          </div>
          <div className="form-group">
            <label>Note</label>
            <textarea
              className="form-control"
              value={form.note}
              onChange={(event) => setForm({ ...form, note: event.target.value })}
            ></textarea>
          </div>
          <div className="row form-group">
            <div className="col-auto">Sudah Terselesaikan?</div>
            <div className="col-auto">
              <Toggle
                checked={form.resolved === "true"}
                onChange={(event) => {
                  if (event.target.checked) {
                    setForm({ ...form, resolved: "true" });
                  } else {
                    setForm({ ...form, resolved: "false" });
                  }
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label>Solusi</label>
            <textarea
              className="form-control"
              value={form.solution}
              onChange={(event) => setForm({ ...form, solution: event.target.value })}
              disabled={!(form.resolved === "true")}
            ></textarea>
          </div>
          <div className="row form-group">
            <div className="col-auto">Langsung Buat Tiket?</div>
            <div className="col-auto">
              <Toggle checked={form.ticketOpt} onChange={(event) => onToggleTicketOpt(event)} />
            </div>
          </div>

          {form.ticketOpt ? (
            <>
              <div className="form-group">
                <label>Nama</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={form.name}
                  onChange={(event) => setForm({ ...form, name: event.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Nomor Telepon</label>
                <input
                  type="text"
                  className="form-control"
                  required
                  value={form.phone_number}
                  onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
                />
              </div>
              <div className="form-group">
                <label>Alamat Pemasangan</label>
                <textarea
                  className="form-control"
                  rows="3"
                  disabled
                  value={form.address}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Prioritas</label>
                <select
                  className="form-control"
                  required
                  value={form.priority}
                  onChange={(event) => setForm({ ...form, priority: event.target.value })}
                >
                  <option value="">-- Prioritas --</option>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                </select>
              </div>
              <div className="form-group">
                <label>Tipe Tiket</label>
                <select
                  className="form-control"
                  required
                  value={form.type}
                  onChange={(event) => setForm({ ...form, type: event.target.value })}
                >
                  <option value="">-- Tipe --</option>
                  <option value="technical">Technical</option>
                  <option value="marketing">Marketing</option>
                  <option value="billing">Billing</option>
                </select>
              </div>
              <div className="form-group">
                <label>Kendala</label>
                <textarea
                  className="form-control"
                  rows="5"
                  required
                  value={form.description}
                  onChange={(event) => setForm({ ...form, description: event.target.value })}
                ></textarea>
              </div>
              <div className="form-group">
                <label>Tugaskan ke:</label>
                <select
                  className="form-control"
                  required
                  value={form.role}
                  onChange={(event) => setForm({ ...form, role: event.target.value })}
                >
                  <option value="">-- Divisi --</option>
                  {Object.keys(ROLE_ASSIGN_TICKET).map((key, index) => (
                    <option value={key} key={index}>
                      {ROLE_ASSIGN_TICKET[key]}
                    </option>
                  ))}
                </select>
              </div>
            </>
          ) : null}

          <div className="form-group">
            <button className="btn btn-primary btn-block" disabled={loading}>
              <i className="fa fa-plus"></i>{" "}
              {loading ? "Membuat aktivitas baru ..." : "Buat Aktivitas Baru"}
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

export function ModalUpdateActivities({ id, show, onClose, onSuccess }) {
  const onUpdateSuccess = (message) => {
    onSuccess(message);
    onClose();
  };

  return (
    <Modal show={show} onHide={() => onClose()}>
      <Modal.Header closeButton>
        <Modal.Title>Update Aktivitas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormUpdateActivity id={id} onSuccess={onUpdateSuccess} />
      </Modal.Body>
    </Modal>
  );
}

function FormUpdateActivity({ id, onSuccess }) {
  const { customer } = useCustomerContext();

  const { data, fetched } = useDetailActivityHooks({ id: id, customer_id: customer.id });
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [form, setForm] = useState({
    note: "",
    solution: "",
    activity_code_id: "",
    activity_type_id: "",
    resolved: "false",
    ticketOpt: false,
    priority: "low",
    type: "",
    role: "",
    description: "",
    name: "",
    phone_number: "",
    address: "",
  });

  useEffect(() => {
    if (fetched) {
      setForm((prev) => ({
        ...prev,
        note: data.note || "",
        activity_code_id: data.activity_code_id || "",
        activity_type_id: data.activity_type_id || "",
        resolved: data.status === "closed" ? "true" : "false",
        solution: data.solution || "",
        ticketOpt: data.ticket_number ? true : false,
        priority: "low",
        type: "",
        role: "",
        description: "",
        name: "",
        phone_number: "",
        address: "",
      }));
    }
  }, [data, fetched]);

  const handleClose = () => {
    setForm({
      note: "",
      solution: "",
      activity_code_id: "",
      activity_type_id: "",
      resolved: "false",
      ticketOpt: false,
      priority: "low",
      type: "",
      role: "",
      description: "",
      name: "",
      phone_number: "",
      address: "",
    });
    setAlert({ show: false, message: "", type: "" });
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      note: form.note,
      activity_code_id: form.activity_code_id,
      activity_type_id: form.activity_type_id,
      resolved: form.resolved,
      solution: form.resolved === "true" ? form.solution : "",
    };

    if (form.ticketOpt && !data.ticket_number) {
      formData.ticket = {
        priority: form.priority,
        type: form.type,
        role: form.role,
        description: form.description,
        name: form.name,
        phone_number: form.phone_number,
        address: form.address,
      };
    }
    updateActivity(formData);
  };

  const updateActivity = async (form) => {
    setLoading(true);
    try {
      let response = await updateSingleActivityByCustomerId(id, customer.id, form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  const onToggleTicketOpt = (event) => {
    if (event.target.checked) {
      setForm({
        ...form,
        ticketOpt: true,
        name: customer.name || "",
        phone_number: customer.phone_number || "",
        address:
          customer.installation_address.address +
          " RT " +
          customer.installation_address.rt +
          "/RW " +
          customer.installation_address.rw +
          ", " +
          customer.installation_address.addresses.kelurahan +
          ", " +
          customer.installation_address.addresses.kecamatan +
          ", " +
          customer.installation_address.addresses.kota_kab +
          ", " +
          customer.installation_address.addresses.province +
          ", " +
          customer.installation_address.addresses.postal_code,
      });
    } else {
      setForm({ ...form, ticketOpt: false });
    }
  };

  const memoizedInitialOptions = useMemo(
    () =>
      fetched
        ? {
            id: data.activity_code_id,
            code: data.activity_code,
            description: data.activity_code_desc,
          }
        : null,
    [fetched, data?.activity_code_id, data?.activity_code, data?.activity_code_desc]
  );

  return (
    <form onSubmit={(event) => submitData(event)}>
      <AlertBox {...alert} setAlert={setAlert} />
      <div className="form-group">
        <label>Tipe</label>
        <SelectActivityType
          value={form.activity_type_id}
          changeValue={(value) => setForm({ ...form, activity_type_id: value })}
        />
      </div>
      <div className="form-group">
        <label>Kode</label>
        <SearchableActivityCode
          value={form.activity_code_id}
          changeValue={(value) => setForm({ ...form, activity_code_id: value })}
          initialOptions={memoizedInitialOptions}
        />
      </div>
      <div className="form-group">
        <label>Note</label>
        <textarea
          className="form-control"
          value={form.note}
          onChange={(event) => setForm({ ...form, note: event.target.value })}
        ></textarea>
      </div>
      <div className="row form-group">
        <div className="col-auto">Sudah Terselesaikan?</div>
        <div className="col-auto">
          <Toggle
            checked={form.resolved === "true"}
            onChange={(event) => {
              if (event.target.checked) {
                setForm({ ...form, resolved: "true" });
              } else {
                setForm({ ...form, resolved: "false" });
              }
            }}
          />
        </div>
      </div>
      <div className="form-group">
        <label>Solusi</label>
        <textarea
          className="form-control"
          value={form.solution}
          onChange={(event) => setForm({ ...form, solution: event.target.value })}
          disabled={!(form.resolved === "true")}
        ></textarea>
      </div>

      {!data.ticket_number ? (
        <div className="row form-group">
          <div className="col-auto">Buat Tiket?</div>
          <div className="col-auto">
            <Toggle checked={form.ticketOpt} onChange={(event) => onToggleTicketOpt(event)} />
          </div>
        </div>
      ) : (
        <div className="card border-left-success mb-2">
          <div className="card-body">
            Sudah ada Tiket <span className="font-weight-bold">{data.ticket_number}</span>
          </div>
        </div>
      )}

      {form.ticketOpt && !data.ticket_number ? (
        <>
          <div className="form-group">
            <label>Nama</label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Nomor Telepon</label>
            <input
              type="text"
              className="form-control"
              required
              value={form.phone_number}
              onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Alamat Pemasangan</label>
            <textarea className="form-control" rows="3" disabled value={form.address}></textarea>
          </div>
          <div className="form-group">
            <label>Prioritas</label>
            <select
              className="form-control"
              required
              value={form.priority}
              onChange={(event) => setForm({ ...form, priority: event.target.value })}
            >
              <option value="">-- Prioritas --</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>
          <div className="form-group">
            <label>Tipe Tiket</label>
            <select
              className="form-control"
              required
              value={form.type}
              onChange={(event) => setForm({ ...form, type: event.target.value })}
            >
              <option value="">-- Tipe --</option>
              <option value="technical">Technical</option>
              <option value="marketing">Marketing</option>
              <option value="billing">Billing</option>
            </select>
          </div>
          <div className="form-group">
            <label>Kendala</label>
            <textarea
              className="form-control"
              rows="5"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            ></textarea>
          </div>
          <div className="form-group">
            <label>Tugaskan ke:</label>
            <select
              className="form-control"
              required
              value={form.role}
              onChange={(event) => setForm({ ...form, role: event.target.value })}
            >
              <option value="">-- Divisi --</option>
              {Object.keys(ROLE_ASSIGN_TICKET).map((key, index) => (
                <option value={key} key={index}>
                  {ROLE_ASSIGN_TICKET[key]}
                </option>
              ))}
            </select>
          </div>
        </>
      ) : null}

      <div className="form-group">
        <button className="btn btn-primary btn-block" disabled={loading}>
          <i className="fa fa-plus"></i>{" "}
          {loading ? "Menyimpan Perubahan ..." : "Simpan Perubahan Aktivitas"}
        </button>
      </div>
    </form>
  );
}
