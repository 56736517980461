import { useState } from "react";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import Pagination from "../../components/Pagination";
import { useAllCustomerActivitiesHooks } from "../../utils/customer/CustomerRelationUtils";
import moment from "moment";
import AlertBox from "../../components/AlertBox";
import {
  ActivityStatusBadge,
  TicketStatusBadge,
} from "../../components/customer/activities/Activitiesbadge";
import ModalDownloadActivities from "../../components/customer/activities/ModalDownloadActivities";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useControlPermission } from "../../utils/ActionPermissionUtils";

export default function ReportActivitiesPage() {
  const { projects } = useGroupListHooks();
  const { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    q: "",
    status: "",
    project_id: "",
    created_start_date: "",
    created_end_date: "",
  });
  let [filter, setFilter] = useState({
    q: "",
    status: "",
    project_id: "",
    created_start_date: "",
    created_end_date: "",
    page: 1,
    page_size: 25,
  });
  const { activities, meta, loading, errorMsg } = useAllCustomerActivitiesHooks({ filter });

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [modal, setModal] = useState(null);

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      q: "",
      status: "",
      project_id: "",
      created_start_date: "",
      created_end_date: "",
    });
    setFilter({
      q: "",
      status: "",
      project_id: "",
      created_start_date: "",
      created_end_date: "",
      page: 1,
      page_size: 25,
    });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const onSuccess = (message) => {
    setAlert({ show: true, message: message, type: "success" });
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h4 mb-0 text-gray-800">Aktivitas Semua Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-all-customer-crm-activity") ? (
              <button className="btn btn-primary" onClick={() => setModal("download")}>
                <i className="fa fa-download"></i> Download Aktivitas
              </button>
            ) : null}
          </div>
        </div>

        <Box title="Aktivitas Pelanggan" loading={loading}>
          <div className="row mb-4">
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor="customer_number">Pencarian</label>
                <input
                  type="text"
                  name="customer_number"
                  value={formFilter.q}
                  placeholder="Nama Pelanggan/No. Reg/No. Pelanggan"
                  onChange={(event) => changeFilter("q", event.target.value)}
                  className="form-control"
                  id="customer_number"
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor="customer_number">Status</label>
                <select
                  className="form-control"
                  value={formFilter.status}
                  onChange={(event) => changeFilter("status", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  <option value="open">OPEN</option>
                  <option value="in-progress">IN PROGRESS</option>
                  <option value="closed">CLOSED</option>
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="form-group">
                <label htmlFor="projectFilter">Grup/Project</label>
                <select
                  className="form-control"
                  id="projectFilter"
                  value={formFilter.project_id}
                  onChange={(event) => changeFilter("project_id", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  {projects.map((project, index) => (
                    <option value={project.id} key={index}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="from-group">
                <label>Tanggal Dibuat Mulai</label>
                <input
                  type="date"
                  value={formFilter.created_start_date}
                  onChange={(event) => changeFilter("created_start_date", event.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="from-group">
                <label>Tanggal Dibuat Akhir</label>
                <input
                  type="date"
                  value={formFilter.created_end_date}
                  onChange={(event) => changeFilter("created_end_date", event.target.value)}
                  className="form-control"
                />
              </div>
            </div>
            <div className="col-auto">
              <button
                className="btn btn-primary"
                onClick={() => applyFilter()}
                style={{ marginTop: "32px" }}
              >
                Filter
              </button>
              <button
                className="btn btn-default"
                onClick={() => resetFilter()}
                style={{ marginTop: "32px" }}
              >
                Reset
              </button>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />
          {errorMsg ? <div className="alert alert-danger">{errorMsg}</div> : null}
          <div style={{ overflowX: "auto" }}>
            <table className="table table-bordered table-hover table-striped">
              <thead>
                <tr>
                  <th>Status</th>
                  <th>No. Reg</th>
                  <th>Nama Pelanggan</th>
                  <th>Grup/Project</th>
                  <th>No. Activity</th>
                  <th>Activity Type</th>
                  <th>Activity Code</th>
                  <th>Note</th>
                  <th>Solusi</th>
                  <th>No. Tiket</th>
                  <th>Dibuat oleh</th>
                  <th>Dibuat pada</th>
                  <th>Ditutup oleh</th>
                  <th>Ditutup pada</th>
                </tr>
              </thead>
              <tbody>
                {activities.length === 0 ? (
                  <tr>
                    <td colSpan={15} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                ) : null}
                {activities.map((activity) => (
                  <tr key={activity.id}>
                    <td className="text-nowrap">
                      <ActivityStatusBadge status={activity.status} />
                    </td>
                    <td>{activity.registration_number}</td>
                    <td>{activity.customer_name}</td>
                    <td>{activity.project_name || "-"}</td>
                    <td className="text-nowrap">{activity.activity_number}</td>
                    <td className="text-nowrap">{activity.activity_type}</td>
                    <td className="text-nowrap">
                      {activity.activity_code} - {activity.activity_code_desc}
                    </td>
                    <td style={{ minWidth: "200px" }}>{activity.note || "-"}</td>
                    <td style={{ minWidth: "200px" }}>{activity.solution || "-"}</td>
                    <td className="text-nowrap">
                      {activity.ticket_number ? (
                        <>
                          <span>{activity.ticket_number}</span>{" "}
                          <TicketStatusBadge status={activity.ticket_status} />
                        </>
                      ) : (
                        "-"
                      )}
                    </td>
                    <td>{activity.created_by}</td>
                    <td className="text-nowrap">
                      {moment.unix(activity.created_at).format("DD MMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap">{activity.closed_by || "-"}</td>
                    <td className="text-nowrap">
                      {activity.closed_at
                        ? moment.unix(activity.closed_at).format("DD MMM YYYY - HH:mm")
                        : "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalDownloadActivities
        show={modal === "download"}
        onClose={() => setModal(null)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}
