export const ROLE_ASSIGN_TICKET = {
  "cs-outbound": "CS Outbound",
  "cs-inbound": "CS Inbound",
  noc: "NOC",
  "technician-troubleshoot": "Teknisi Troubleshoot",
  "technician-maintenance-service": "Teknisi Maintenance Service",
  "technician-field-engineer": "Teknisi Field Engineer",
  technician: "Teknisi",
  cs: "CS",
  sales: "Sales",
  finance: "Finance",
};
