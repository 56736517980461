import { useState } from "react";
import { Modal } from "react-bootstrap";
import { downloadTickets } from "../../api/ticket";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import AlertBox from "../AlertBox";
import { ROLE_ASSIGN_TICKET } from "../../tools/tickettools";

export default function ModalDownloadTickets({ show, onClose, onSuccess }) {
  const [formFilter, setFormFilter] = useState({
    date_from: "",
    date_until: "",
    project_id: "",
    assign_to: "",
    status: "",
    type: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const { projects } = useGroupListHooks();

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({
      date_from: "",
      date_until: "",
      project_id: "",
      status: "",
      type: "",
      assign_to: "",
    });
  };

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadTickets({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Ticket.xlsx");
      onSuccess("Berhasil mengunduh Data Ticket.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Data Tiket</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form>
          <div className="form-group">
            <label>Tanggal Dari</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.date_from}
              onChange={(event) => setFormFilter({ ...formFilter, date_from: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Tanggal Sampai</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.date_until}
              onChange={(event) => setFormFilter({ ...formFilter, date_until: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              type="text"
              name="status"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
              className="form-control"
              id="status"
            >
              <option value="">Default</option>
              <option value="open">Open</option>
              <option value="working">Working</option>
              <option value="closed">Closed</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="role">Tipe</label>
            <select
              name="type"
              value={formFilter.type}
              onChange={(event) => setFormFilter({ ...formFilter, type: event.target.value })}
              className="form-control"
              id="type"
            >
              <option value="">Semua</option>
              <option value="follow-up">Follow Up</option>
              <option value="technical">Technical</option>
              <option value="marketing">Marketing</option>
              <option value="billing">Billing</option>
            </select>
          </div>
          <div className="form-group">
            <label>Penugasan ke</label>
            <select
              value={formFilter.assign_to}
              onChange={(event) => setFormFilter({ ...formFilter, assign_to: event.target.value })}
              className="form-control"
            >
              <option value="">Semua</option>
              {Object.keys(ROLE_ASSIGN_TICKET).map((key, index) => (
                <option value={key} key={index}>
                  {ROLE_ASSIGN_TICKET[key]}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="projectFilter">Grup/Project</label>
            <select
              className="form-control"
              id="projectFilter"
              value={formFilter.project_id}
              onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
            >
              <option value="">SEMUA</option>
              {projects.map((project, index) => (
                <option value={project.id} key={index}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
