import { useState } from "react";
import moment from "moment";
import Box from "../../components/Box";
import AlertBox from "../../components/AlertBox";
import MainLayout from "../../components/layouts/MainLayout";
import Pagination from "../../components/Pagination";
import { useAllCustomersHooks } from "../../utils/CustomerUtilHooks";
import { convertToRupiah } from "../../tools/numerictools";
import { BUILDING_STATUS } from "../../tools/userdatatools";
import { useInternetPackageListHooks } from "../../utils/InternetPackageUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import ModalCustomerDetail from "../../components/customer/ModalCustomerDetail";
import ModalDownloadExpiredCustomer from "../../components/customer/ModalDownloadExpiredCustomer";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import ModalCreateFollowupTicketCustomer from "../../components/ticketing/ModalCreateFollowupTicketCustomer";
import ModalReportFollowupTicketing from "../../components/ticketing/ModalReportFollowupTicketing";

export default function CustomerExpiredPage() {
  let { hasAccess } = useControlPermission();

  let [formFilter, setFormFilter] = useState({
    customer_name: "",
    followed_up: "",
    n: "",
    internet_package: "",
    project_id: "",
    overdue: 2,
    sort_type: "ASC",
    sort_by: "overdue",
  });
  let [filter, setFilter] = useState({
    overdue: 2,
    followed_up: "",
    customer_name: "",
    n: "",
    internet_package: "",
    project_id: "",
    page: 1,
    page_size: 10,
    sort_type: "ASC",
    sort_by: "overdue",
  });
  let [selectedId, setSelectedId] = useState(null);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  let [showModalDetail, setShowModalDetail] = useState(false);
  let [showModalDownload, setShowModalDownload] = useState(false);
  let [showModalTicket, setShowModalTicket] = useState(false);
  let [showModalFollowUp, setShowModalFollowUp] = useState(false);

  const customersData = useAllCustomersHooks({ filter });
  const internetPackage = useInternetPackageListHooks();
  const { projects } = useGroupListHooks();

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(true);
  };

  function applyFilter() {
    let form_filter = {
      ...filter,
      ...formFilter,
      page: 1,
    };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({
      customer_name: "",
      n: "",
      followed_up: "",
      internet_package: "",
      project_id: "",
      overdue: 2,
    });
    setFilter({
      ...filter,
      customer_name: "",
      followed_up: "",
      n: "",
      internet_package: "",
      project_id: "",
      overdue: 2,
      page: 1,
      page_size: 10,
    });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const onSuccess = (message) => {
    customersData.getCustomers(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const changeSort = (field) => {
    if (field === formFilter.sort_by) {
      let sortType = filter.sort_type === "ASC" ? "DESC" : "ASC";
      setFilter({ ...filter, ...formFilter, sort_type: sortType });
    }
  };

  const toggleModalTicket = (id) => {
    setSelectedId(id);
    setShowModalTicket(true);
  };

  const toggleModalFollowUp = (id) => {
    setSelectedId(id);
    setShowModalFollowUp(true);
  };

  return (
    <MainLayout resource_key="manage-customer">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Pelanggan Belum Bayar</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-customer") && (
              <button className="btn btn-primary" onClick={() => setShowModalDownload(true)}>
                <i className="fas fa-download"></i> Unduh Data Pelanggan Belum Bayar
              </button>
            )}
          </div>
        </div>

        <Box
          title={`Daftar Pelanggan yang Sudah Lewat Jatuh Tempo (Total Data: ${customersData.meta.count})`}
          loading={customersData.loading}
        >
          <div className="mb-4">
            <div className="row">
              <div className="col-md-2">
                <label htmlFor="customer_number">No. Pelanggan/Registrasi</label>
                <input
                  type="text"
                  name="customer_number"
                  value={formFilter.n}
                  onChange={(event) => changeFilter("n", event.target.value)}
                  className="form-control"
                  id="customer_number"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="customer_name">Nama Pelanggan</label>
                <input
                  type="text"
                  name="customer_name"
                  value={formFilter.customer_name}
                  onChange={(event) => changeFilter("customer_name", event.target.value)}
                  className="form-control"
                  id="customer_name"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="overdue">Keterlambatan</label>
                <input
                  type="number"
                  name="overdue"
                  value={formFilter.overdue}
                  onChange={(event) => changeFilter("overdue", event.target.value)}
                  className="form-control"
                  id="overdue"
                />
              </div>
              <div className="col-md-2">
                <label htmlFor="package_name">Paket</label>
                <select
                  className="form-control input"
                  value={formFilter.internet_package}
                  onChange={(event) => changeFilter("internet_package", event.target.value)}
                >
                  <option value="" disabled>
                    {" "}
                    -- Pilih Paket --
                  </option>
                  {internetPackage.data.map((pack, index) => (
                    <option key={index} value={pack.id}>
                      {pack.name} - ({pack.internet_package_varian.name}) - Rp{" "}
                      {convertToRupiah(pack.price)} - Rp {convertToRupiah(pack.installation_cost)}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="package_name">Follow Up</label>
                <select
                  className="form-control input"
                  value={formFilter.followed_up}
                  onChange={(event) => changeFilter("followed_up", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  <option value="true">Sudah Follow Up</option>
                  <option value="false">Belum Follow Up</option>
                </select>
              </div>
              <div className="col-md-2">
                <label htmlFor="projectFilter">Grup/Project</label>
                <select
                  className="form-control"
                  id="projectFilter"
                  value={formFilter.project_id}
                  onChange={(event) => changeFilter("project_id", event.target.value)}
                >
                  <option value="">SEMUA</option>
                  {projects.map((project, index) => (
                    <option value={project.id} key={index}>
                      {project.name}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col-auto">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />

          <div style={{ overflowX: "auto" }}>
            <table className="table table-hover table-bordered table-striped">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Pelanggan</th>
                  <th>No. Registrasi</th>
                  <th>Tanggal Terpasang</th>
                  <th>Jatuh Tempo</th>
                  <th className="text-nowrap" onClick={() => changeSort("overdue")}>
                    Keterlambatan{" "}
                    <span className="pl-4 text-primary">
                      {filter.sort_type === "ASC" ? (
                        <i className="fa-solid fa-arrow-down-short-wide"></i>
                      ) : (
                        <i className="fa-solid fa-arrow-up-short-wide"></i>
                      )}
                    </span>
                  </th>
                  <th>Nama</th>
                  <th>Paket</th>
                  <th>Durasi</th>
                  <th>Biaya</th>
                  <th>Kepemilikan Lokasi</th>
                  <th>Grup/Project</th>
                  <th>Serial ONT</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {customersData.customers.length === 0 && (
                  <tr>
                    <td colSpan={14} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {customersData.customers.map((customer, index) => (
                  <tr key={index}>
                    <td>
                      {hasAccess("view-customer") && (
                        <button
                          className="btn btn-block btn-sm mb-1 btn-primary text-nowrap"
                          onClick={() => toggleModalDetail(customer.id)}
                        >
                          <i className="fas fa-eye"></i> Detail
                        </button>
                      )}
                      {customer.is_active !== 2 &&
                        !customer.follow_up_ticket_id &&
                        hasAccess("create-customer-ticket") && (
                          <button
                            className="btn btn-block btn-sm mb-1 btn-warning text-nowrap"
                            onClick={() => toggleModalTicket(customer.id)}
                          >
                            <i className="fas fa-headset"></i> Buat Follow Up
                          </button>
                        )}

                      {customer.is_active !== 2 &&
                        customer.follow_up_ticket_id &&
                        hasAccess("update-followup-ticket") && (
                          <button
                            className="btn btn-block btn-sm mb-1 btn-custom-orange text-nowrap"
                            onClick={() => toggleModalFollowUp(customer.follow_up_ticket_id)}
                          >
                            <i className="fas fa-file-pen"></i> Update Follow Up
                          </button>
                        )}
                    </td>
                    <td>{customer.customer_number}</td>
                    <td className="text-nowrap">{customer.registration.registration_number}</td>
                    <td className="text-nowrap">
                      {moment.unix(customer.createdAt).format("DD MMMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap">
                      {moment(customer.plan.end_date).format("DD MMMM YYYY - HH:mm")}
                    </td>
                    <td className="text-nowrap text-center">{customer.plan.overdue} hari</td>
                    <td className="text-nowrap">{customer.name}</td>
                    <td className="text-nowrap">{customer.internet_package.name}</td>
                    <td className="text-nowrap">
                      {customer.internet_package.internet_package_varian.validity_period} hari
                    </td>
                    <td className="text-nowrap">
                      Rp. {convertToRupiah(customer.internet_package.price)}
                    </td>
                    <td className="text-nowrap">
                      {BUILDING_STATUS[customer.building_ownership_status]}
                    </td>
                    <td className="text-nowrap">{customer.project?.name || "-"}</td>
                    <td className="text-nowrap">
                      {customer.inventory_modem_pair?.modem?.serial_number_modem}
                    </td>
                    <td>
                      {customer.is_active === 0 && (
                        <span className="badge badge-warning text-md">Isolir</span>
                      )}
                      {customer.is_active === 1 && (
                        <span className="badge badge-success text-md">Aktif</span>
                      )}
                      {customer.is_active === 2 && (
                        <span className="badge badge-danger text-md">Dismantled</span>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {customersData.meta.count > customersData.meta.page_size && (
            <Pagination
              totalpage={Math.ceil(customersData.meta.count / customersData.meta.page_size)}
              selectedpage={customersData.meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(customersData.meta.page + 1)}
              prev={() => gotoPage(customersData.meta.page - 1)}
            />
          )}
        </Box>

        <ModalCustomerDetail
          show={showModalDetail}
          id={showModalDetail ? selectedId : ""}
          onClose={() => setShowModalDetail(false)}
        />
        <ModalDownloadExpiredCustomer
          show={showModalDownload}
          onClose={() => setShowModalDownload(false)}
          onSuccess={onSuccess}
        />
        <ModalCreateFollowupTicketCustomer
          show={showModalTicket}
          id={showModalTicket ? selectedId : ""}
          onClose={() => setShowModalTicket(false)}
          onSuccess={onSuccess}
        />
        <ModalReportFollowupTicketing
          show={showModalFollowUp}
          id={showModalFollowUp ? selectedId : ""}
          onClose={() => setShowModalFollowUp(false)}
          onSuccess={onSuccess}
        />
      </div>
    </MainLayout>
  );
}
