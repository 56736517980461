import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import AlertBox from "../AlertBox";
import { useGetCustomerHooks } from "../../utils/CustomerUtilHooks";
import { createTicket } from "../../api/ticket";
import { ROLE_ASSIGN_TICKET } from "../../tools/tickettools";

export default function ModalCreateTicketCustomer({ show, id, onClose, onSuccess }) {
  const { data, fetched } = useGetCustomerHooks({ id });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  let [loading, setLoading] = useState(false);

  let [form, setForm] = useState({
    type: "",
    description: "",
    role: "",
    priority: "",
    name: "",
    phone_number: "",
    address: "",
  });

  const handleClose = () => {
    setForm({
      type: "",
      description: "",
      role: "",
      priority: "",
      name: "",
      phone_number: "",
      address: "",
    });
    onClose();
  };

  useEffect(() => {
    if (fetched === true) {
      setForm({
        ...form,
        name: data.name || "",
        phone_number: data.phone_number || "",
        address:
          data.installation_address.address +
          " RT " +
          data.installation_address.rt +
          "/RW " +
          data.installation_address.rw +
          ", " +
          data.installation_address.addresses.kelurahan +
          ", " +
          data.installation_address.addresses.kecamatan +
          ", " +
          data.installation_address.addresses.kota_kab +
          ", " +
          data.installation_address.addresses.province +
          ", " +
          data.installation_address.addresses.postal_code,
      });
    }
  }, [fetched]);

  const submitData = (event) => {
    event.preventDefault();
    let formData = {
      priority: form.priority,
      type: form.type,
      role: form.role,
      description: form.description,
      customer: {
        id: data.id,
        name: form.name,
        phone_number: form.phone_number,
        address: form.address,
      },
    };

    submitTicket(formData);
  };

  const submitTicket = async (form) => {
    setLoading(true);
    try {
      let response = await createTicket(form);
      onSuccess(response.data.message);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Buat Tiket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <AlertBox {...alert} setAlert={setAlert} />
          <div className="form-group">
            <label>Nama</label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Nomor Telepon</label>
            <input
              type="text"
              className="form-control"
              required
              value={form.phone_number}
              onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label>Alamat Pemasangan</label>
            <textarea className="form-control" rows="3" disabled value={form.address}></textarea>
          </div>
          <div className="form-group">
            <label>Prioritas</label>
            <select
              className="form-control"
              required
              value={form.priority}
              onChange={(event) => setForm({ ...form, priority: event.target.value })}
            >
              <option value="">-- Prioritas --</option>
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
          </div>
          <div className="form-group">
            <label>Tipe Tiket</label>
            <select
              className="form-control"
              required
              value={form.type}
              onChange={(event) => setForm({ ...form, type: event.target.value })}
            >
              <option value="">-- Tipe --</option>
              <option value="technical">Technical</option>
              <option value="marketing">Marketing</option>
              <option value="billing">Billing</option>
            </select>
          </div>
          <div className="form-group">
            <label>Kendala</label>
            <textarea
              className="form-control"
              rows="10"
              required
              value={form.description}
              onChange={(event) => setForm({ ...form, description: event.target.value })}
            ></textarea>
          </div>
          <div className="form-group">
            <label>Tugaskan ke:</label>
            <select
              className="form-control"
              required
              value={form.role}
              onChange={(event) => setForm({ ...form, role: event.target.value })}
            >
              <option value="">-- Divisi --</option>
              {Object.keys(ROLE_ASSIGN_TICKET).map((key, index) => (
                <option value={key} key={index}>
                  {ROLE_ASSIGN_TICKET[key]}
                </option>
              ))}
            </select>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end">
            <button type="button" onClick={() => handleClose()} className="btn btn-secondary mr-1">
              Batal
            </button>
            <button type="submit" className="btn btn-primary" disabled={loading}>
              Simpan
            </button>
          </div>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
