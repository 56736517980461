import { useState } from "react";
import { Modal } from "react-bootstrap";

import { useGroupListHooks } from "../../../utils/ProjectsUtilHooks";
import AlertBox from "../../AlertBox";
import { downloadActivitiesCustomer } from "../../../api/customer";

export default function ModalDownloadActivities({ show, onClose, onSuccess }) {
  const [formFilter, setFormFilter] = useState({
    project_id: "",
    status: "",
    created_start_date: "",
    created_end_date: "",
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [loading, setLoading] = useState(false);
  const { projects } = useGroupListHooks();

  const handleClose = () => {
    resetFilter();
    onClose();
  };

  const resetFilter = () => {
    setFormFilter({
      project_id: "",
      status: "",
      created_start_date: "",
      created_end_date: "",
    });
  };

  const downloadFile = async () => {
    setLoading(true);
    try {
      let response = await downloadActivitiesCustomer({ ...formFilter });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "Data Aktivitas.xlsx");
      onSuccess("Berhasil mengunduh Data Aktivitas.xlsx");
      document.body.appendChild(link);
      link.click();
      handleClose();
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.response) {
        let { data } = error.response;
        let responseObj = await data.text();
        let responseData = JSON.parse(responseObj);
        message = responseData?.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message: message, type: "danger" });
    }
    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="md">
      <Modal.Header closeButton>
        <Modal.Title>Download Data Aktivitas</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <AlertBox {...alert} setAlert={setAlert} />
        <form>
          <div className="form-group">
            <label htmlFor="status">Status</label>
            <select
              type="text"
              name="status"
              value={formFilter.status}
              onChange={(event) => setFormFilter({ ...formFilter, status: event.target.value })}
              className="form-control"
              id="status"
            >
              <option value="">SEMUA</option>
              <option value="open">Open</option>
              <option value="in-progress">In Progress</option>
              <option value="closed">Closed</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="projectFilter">Grup/Project</label>
            <select
              className="form-control"
              id="projectFilter"
              value={formFilter.project_id}
              onChange={(event) => setFormFilter({ ...formFilter, project_id: event.target.value })}
            >
              <option value="">SEMUA</option>
              {projects.map((project, index) => (
                <option value={project.id} key={index}>
                  {project.name}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label>Tanggal Dibuat Dari:</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.created_start_date}
              onChange={(event) =>
                setFormFilter({ ...formFilter, created_start_date: event.target.value })
              }
            />
          </div>
          <div className="form-group">
            <label>Tanggal Dibuat Akhir:</label>
            <input
              type="date"
              className="form-control"
              value={formFilter.created_end_date}
              onChange={(event) =>
                setFormFilter({ ...formFilter, created_end_date: event.target.value })
              }
            />
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <button className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
        <button className="btn btn-primary" onClick={() => downloadFile()} disabled={loading}>
          <i className="fas fa-download"></i> {!loading ? "Unduh Data" : "Harap Tunggu ..."}
        </button>
      </Modal.Footer>
    </Modal>
  );
}
