import { useEffect, useState } from "react";
import Pagination from "../../components/Pagination";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import moment from "moment";
import { convertToRupiah } from "../../tools/numerictools";
import { useBillingsHooks } from "../../utils/finance/BillingUtils";
import { downloadBillingInvoiceById, reminderWhatsappBilling } from "../../api/finance";
import ModalDownloadBilling from "../../components/billing/ModalDownloadBilling";
import ModalUpdateBilling from "../../components/billing/ModalUpdateBilling";
import ModalDetailBilling from "../../components/billing/ModalDetailBilling";
import ModalConfirmBilling from "../../components/billing/ModalConfirmBilling";
import ModalResetBilling from "../../components/billing/ModalResetBilling";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { BILLING_TYPE } from "../../tools/billingdatatools";
import ModalResendBilling from "../../components/billing/ModalResendBilling";

export default function BillingPage() {
  let { hasAccess } = useControlPermission();

  const [formFilter, setFormFilter] = useState({
    status: "UNPAID",
    type: "",
    name: "",
    n: "",
    payment_method: "",
    payment_provider: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    created_end_date: moment().format("YYYY-MM-DD"),
    amt_from: "",
    amt_until: "",
    project_id: "",
  });
  const [filter, setFilter] = useState({
    status: "UNPAID",
    type: "",
    name: "",
    n: "",
    payment_method: "",
    payment_provider: "",
    confirmed_start_date: "",
    confirmed_end_date: "",
    created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
    created_end_date: moment().format("YYYY-MM-DD"),
    amt_from: "",
    amt_until: "",
    project_id: "",
    page: 1,
    page_size: 10,
  });

  const { projects } = useGroupListHooks();

  const [alert, setAlert] = useState({ show: false, message: "", type: "" });
  const [selectedId, setSelectedId] = useState("");
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalDetail, setShowModalDetail] = useState(false);
  const [showModalUpdate, setShowModalUpdate] = useState(false);
  const [showModalConfirm, setShowModalConfirm] = useState(false);
  const [showModalReset, setShowModalReset] = useState(false);
  const [showModalResend, setShowModalResend] = useState(false);

  let billings = useBillingsHooks({ filter });

  useEffect(() => {
    if (billings.errorMsg) {
      setAlert({ show: true, message: billings.errorMsg, type: "danger" });
    }
  }, [billings.errorMsg]);

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const toggleModalDetail = (id) => {
    setSelectedId(id);
    setShowModalDetail(true);
  };

  const toggleModalEdit = (id) => {
    setSelectedId(id);
    setShowModalUpdate(true);
  };

  const toggleModalConfirm = (id) => {
    setSelectedId(id);
    setShowModalConfirm(true);
  };

  const toggleModalResend = (id) => {
    setSelectedId(id);
    setShowModalResend(true);
  };

  const toggleModalReset = (id) => {
    setSelectedId(id);
    setShowModalReset(true);
  };

  const applyFilter = () => {
    setFilter({
      ...filter,
      ...formFilter,
      page: 1,
    });
  };

  const resetFilter = () => {
    setFormFilter({
      status: "UNPAID",
      type: "",
      name: "",
      n: "",
      payment_method: "",
      payment_provider: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
      created_end_date: moment().format("YYYY-MM-DD"),
      amt_from: "",
      amt_until: "",
      project_id: "",
    });
    setFilter({
      status: "UNPAID",
      type: "",
      name: "",
      n: "",
      payment_method: "",
      payment_provider: "",
      confirmed_start_date: "",
      confirmed_end_date: "",
      created_start_date: moment().subtract(3, "months").format("YYYY-MM-DD"),
      created_end_date: moment().format("YYYY-MM-DD"),
      amt_from: "",
      amt_until: "",
      project_id: "",
      page: 1,
      page_size: 10,
    });
  };

  const gotoPage = (page) => {
    let filter_page = { ...filter, page: page };
    setFilter(filter_page);
  };

  const onSuccess = (message) => {
    billings.getBillings(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  const onFailure = (message) => {
    billings.getBillings(filter);
    setAlert({ show: true, message: message, type: "danger" });
  };

  return (
    <MainLayout resource_key="finance">
      <div className="container-fluid">
        <div className="row mb-3">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Tagihan Pelanggan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-billing") && (
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fas fa-download"></i> Unduh Data Tagihan
              </button>
            )}
          </div>
        </div>
        <Box title={`Tagihan (Total: ${billings.meta.count})`} loading={billings.loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Nomor</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.n}
                    onChange={(event) => changeFilter("n", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Nama Pelanggan</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.name}
                    onChange={(event) => changeFilter("name", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Tipe</label>
                  <select
                    name="type"
                    value={formFilter.type}
                    onChange={(event) => changeFilter("type", event.target.value)}
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="REGISTRATION">REGISTRATION</option>
                    <option value="CUSTOMER">CUSTOMER</option>
                    <option value="CUSTOMER-RELOCATE">CUSTOMER RELOCATE</option>
                    <option value="ADDITIONAL-ITEM">CUSTOM BILLING</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Status</label>
                  <select
                    name="status"
                    value={formFilter.status}
                    onChange={(event) => changeFilter("status", event.target.value)}
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="PAID">PAID</option>
                    <option value="UNPAID">UNPAID</option>
                    <option value="CANCELED">CANCELED</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">Metode Pembayaran</label>
                  <select
                    name="status"
                    value={formFilter.payment_method}
                    onChange={(event) =>
                      setFormFilter({
                        ...formFilter,
                        payment_method: event.target.value,
                        payment_provider: "",
                      })
                    }
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="bank-transfer">Bank Transfer</option>
                    <option value="adakita">Adakita</option>
                    <option value="virtual-account">Virtual Account</option>
                    <option value="alfamart">Alfamart</option>
                    <option value="pulsapro">Pulsapro</option>
                    <option value="dukodu">Dukodu</option>
                    <option value="indomaret">Indomaret</option>
                    <option value="qris">QRIS</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="registration_type">VA Payment Provider</label>
                  <select
                    name="status"
                    value={formFilter.payment_provider}
                    disabled={formFilter.payment_method !== "virtual-account"}
                    onChange={(event) => changeFilter("payment_provider", event.target.value)}
                    className="form-control"
                    id="status"
                  >
                    <option value="">SEMUA</option>
                    <option value="bca">BCA</option>
                    <option value="winpay">WINPAY</option>
                    <option value="nicepay">NICEPAY</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Konfirm Dari</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.confirmed_start_date}
                    onChange={(event) => changeFilter("confirmed_start_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Konfirm Sampai</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.confirmed_end_date}
                    onChange={(event) => changeFilter("confirmed_end_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Total Pembayaran Minimal</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formFilter.amt_from}
                    onChange={(event) => changeFilter("amt_from", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Total Pembayaran Maksimal</label>
                  <input
                    type="number"
                    className="form-control"
                    value={formFilter.amt_until}
                    onChange={(event) => changeFilter("amt_until", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Billing Dari</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.created_start_date}
                    onChange={(event) => changeFilter("created_start_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Tanggal Billing Sampai</label>
                  <input
                    type="date"
                    className="form-control"
                    value={formFilter.created_end_date}
                    onChange={(event) => changeFilter("created_end_date", event.target.value)}
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="projectFilter">Grup/Project</label>
                  <select
                    className="form-control"
                    id="projectFilter"
                    value={formFilter.project_id}
                    onChange={(event) => changeFilter("project_id", event.target.value)}
                  >
                    <option value="">SEMUA</option>
                    {projects.map((project, index) => (
                      <option value={project.id} key={index}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-sm">
                <button
                  className="btn btn-primary"
                  onClick={() => applyFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Filter
                </button>
                <button
                  className="btn btn-default"
                  onClick={() => resetFilter()}
                  style={{ marginTop: "32px" }}
                >
                  Reset
                </button>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />

          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered table-hover">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Billing</th>
                  <th>No. Reg/Pelanggan</th>
                  <th>Nama</th>
                  <th>Total Pembayaran</th>
                  <th>Status</th>
                  <th>Jatuh Tempo Pembayaran</th>
                  <th>Jatuh Tempo Layanan</th>
                  <th>Periode Berikutnya</th>
                  <th>Konfirmasi Pembayaran</th>
                  <th>Metode</th>
                  <th>Paket</th>
                  <th>Tipe</th>
                  <th>Project</th>
                </tr>
              </thead>
              <tbody>
                {billings.data.length === 0 && (
                  <tr>
                    <td colSpan={16} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {billings.data.map((billing, index) => (
                  <tr key={index}>
                    {/* Action */}
                    <td className="text-nowrap">
                      {billing.status === "UNPAID" &&
                        billing.is_expired === 0 &&
                        hasAccess("confirm-billing") && (
                          <button
                            className="btn btn-sm btn-block btn-success mb-1 text-nowrap"
                            onClick={() => toggleModalConfirm(billing.id)}
                          >
                            <i className="fa fa-check-square"></i> Confirm
                          </button>
                        )}
                      {hasAccess("view-finance-billing") && (
                        <button
                          className="btn btn-sm btn-block btn-primary"
                          onClick={() => toggleModalDetail(billing.id)}
                        >
                          <i className="fa fa-eye"></i> Lihat Detail
                        </button>
                      )}
                      {billing.status !== "PAID" && hasAccess("edit-billing") && (
                        <button
                          className="btn btn-sm btn-block btn-info"
                          onClick={() => toggleModalEdit(billing.id)}
                        >
                          <i className="fa fa-pencil-alt"></i> Edit Billing
                        </button>
                      )}
                      {billing.status === "UNPAID" &&
                        billing.is_expired === 0 &&
                        hasAccess("reminder-billing") && (
                          <button
                            className="btn btn-sm btn-block btn-warning text-nowrap"
                            onClick={() => toggleModalResend(billing.id)}
                          >
                            <i className="fas fa-bell"></i> Kirim Pengingat
                          </button>
                        )}
                      {billing.status === "PAID" && hasAccess("cancel-billing") && (
                        <button
                          className="btn btn-sm btn-block btn-danger mb-1 text-nowrap"
                          onClick={() => toggleModalReset(billing.id)}
                        >
                          <i className="fa-solid fa-rotate-left"></i> Batalkan
                        </button>
                      )}
                      {hasAccess("view-invoice") && (
                        <DownloadBillingInvoice
                          billing={billing}
                          onSuccess={onSuccess}
                          onError={onFailure}
                        />
                      )}
                    </td>
                    {/* Nomor Billing */}
                    <td className="text-nowrap">{billing.billing_number || "-"}</td>
                    {/* Nomor Pelanggan / Registrasi */}
                    <td>
                      {billing.type === "REGISTRATION"
                        ? billing.registration.registration_number
                        : billing.customer.customer_number}
                    </td>
                    {/* Nama Pelanggan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? billing.registration.name
                        : billing.customer.name}
                    </td>
                    {/* Total Pembayaran */}
                    <td className="text-nowrap">Rp. {convertToRupiah(billing.final_amount)}</td>
                    {/* Status */}
                    <td>
                      {billing.status}{" "}
                      {billing.is_expired === 1 ? (
                        <span className="badge badge-danger">expired</span>
                      ) : (
                        ""
                      )}
                    </td>
                    {/* Jatuh Tempo Pembayaran */}
                    <td className="text-nowrap">
                      {moment.unix(billing.due_date).format("DD MMM YYYY")}
                    </td>
                    {/* Jatuh Tempo Layanan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? "-"
                        : billing.plan_end_date
                        ? moment.unix(billing.plan_end_date).format("DD MMM YYYY")
                        : "-"}
                    </td>
                    {/* Periode Berikutnya */}
                    <td className="text-nowrap">
                      {billing.payment?.start_period
                        ? moment(billing.payment.start_period).format("DD MMM YYYY") +
                          " - " +
                          moment(billing.payment.end_period).format("DD MMM YYYY")
                        : "-"}
                    </td>
                    {/* Konfirmasi Pembayaran */}
                    <td className="text-nowrap">
                      {billing.status === "UNPAID"
                        ? "-"
                        : billing.confirmed_date
                        ? moment.unix(billing.confirmed_date).format("DD MMM YYYY - HH:mm")
                        : "-"}
                    </td>
                    {/* Metode */}
                    <td className="text-nowrap">{billing.payment.payment_method || "-"}</td>
                    {/* Paket */}
                    <td>{billing.internet_package.name}</td>
                    {/* Tipe Billing */}
                    <td>{BILLING_TYPE[billing.type] || "-"}</td>
                    {/* Nama Pelanggan */}
                    <td className="text-nowrap">
                      {billing.type === "REGISTRATION"
                        ? billing.registration.project?.name || "-"
                        : billing.customer.project?.name || "-"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="mt-4">
            {billings.meta.count > billings.meta.page_size && (
              <Pagination
                totalpage={Math.ceil(billings.meta.count / billings.meta.page_size)}
                selectedpage={billings.meta.page}
                clickpage={(page) => gotoPage(page)}
                next={() => gotoPage(billings.meta.page + 1)}
                prev={() => gotoPage(billings.meta.page - 1)}
              />
            )}
          </div>
        </Box>
      </div>

      <ModalDownloadBilling
        show={showModalDownload}
        onClose={toggleModalDownload}
        onSuccess={onSuccess}
      />
      <ModalDetailBilling
        show={showModalDetail}
        id={showModalDetail ? selectedId : ""}
        onClose={() => setShowModalDetail(false)}
      />
      <ModalUpdateBilling
        show={showModalUpdate}
        id={showModalUpdate ? selectedId : ""}
        onClose={() => setShowModalUpdate(false)}
        onSuccess={onSuccess}
      />
      <ModalConfirmBilling
        show={showModalConfirm}
        id={showModalConfirm ? selectedId : ""}
        onClose={() => setShowModalConfirm(false)}
        onSuccess={onSuccess}
      />
      <ModalResendBilling
        show={showModalResend}
        id={showModalResend ? selectedId : ""}
        onClose={() => setShowModalResend(false)}
        onSuccess={onSuccess}
      />
      <ModalResetBilling
        show={showModalReset}
        id={showModalReset ? selectedId : ""}
        onClose={() => setShowModalReset(false)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function RemindWhatsappButton({ id, onSuccess, onFailure }) {
  const [processing, setProcessing] = useState(false);
  const remindWhatsapp = async (id) => {
    setProcessing(true);
    try {
      let { data } = await reminderWhatsappBilling(id);
      onSuccess(data.message);
    } catch (error) {
      if (error.response) {
        let { data } = error.response;
        if (data.message) {
          onFailure(data.message);
        } else {
          onFailure("Something Error Happened");
        }
      } else {
        onFailure("Something Error Happened");
      }
    }
    setProcessing(false);
  };

  if (processing) {
    return (
      <button className="btn btn-sm btn-block btn-warning text-nowrap" disabled>
        <i className="fab fa-whatsapp"></i> Mengirim Pesan ...
      </button>
    );
  }

  return (
    <button
      className="btn btn-sm btn-block btn-warning text-nowrap"
      onClick={() => remindWhatsapp(id)}
    >
      <i className="fab fa-whatsapp"></i> Pengingat Whatsapp
    </button>
  );
}

function DownloadBillingInvoice({ billing, onSuccess, onError }) {
  let [loading, setLoading] = useState(false);

  const downloadFile = async () => {
    setLoading(true);

    try {
      let { data } = await downloadBillingInvoiceById(billing.id);
      window.open(data.data.url, "_blank");
    } catch (error) {
      let message = "Something wrong ... ";

      if (error.message) {
        message = error.message;
      }

      onError(message);
    }
    setLoading(false);
  };

  if (loading) {
    return (
      <button className="btn btn-sm btn-block btn-custom-purple mb-1 text-nowrap" disabled>
        <i className="fas fa-download"></i> Membuka ...
      </button>
    );
  }
  return (
    <button
      className="btn btn-sm btn-block btn-custom-purple mb-1 text-nowrap"
      onClick={() => downloadFile()}
    >
      <i className="fa-solid fa-file-invoice"></i>{" "}
      {billing.status === "PAID" ? "Receipt" : "Invoice"}
    </button>
  );
}
