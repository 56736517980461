import { useEffect, useState } from "react";
import Select from "react-select";
import { Modal } from "react-bootstrap";
import { registerTechnician } from "../../api/technician";
import { useTechnicianRolesListHooks } from "../../utils/PermissionManageUtils";
import { useCityRegionHooks } from "../../utils/RegionHooks";
import ImageUploader from "../ImageUploader";
import { RequiredAsterisk } from "../FormHelper";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import Toggle from "react-toggle";
import ConfidentialImageUploader from "../ConfidentialImageUploader";

export default function ModalCreateTechnician({ show, onClose, onSuccess }) {
  let [form, setForm] = useState({
    name: "",
    email: "",
    role: "",
    phone_number: "",
    password: "",
    image_url: "",
    commission_disbursement: 0,
    disbursement_account_id: "",
    id_card_number: "",
    id_card_name: "",
    id_card_image_url: "",
    disbursement_service: "pulsapro",
  });
  let [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [roleOpt, setRoleOpt] = useState([]);

  const [projectOpt, setProjectOpt] = useState([]);
  const [selectedProjects, setSelectedProjects] = useState([]);

  const region = useCityRegionHooks();
  const { roles } = useTechnicianRolesListHooks();
  const { projects } = useGroupListHooks();

  useEffect(() => {
    let rolesTemp = {};

    roles.forEach((role) => {
      if (role.name === "technician" || role.name === "helper") {
        rolesTemp[role.name] = role.id;
      }
    });

    setRoleOpt(rolesTemp);
  }, [roles]);

  useEffect(() => {
    let projectsTemp = [];

    projects.forEach((project) => {
      let temp = {
        value: project.id,
        label: project.name,
      };
      projectsTemp.push(temp);

      return true;
    });

    setProjectOpt(projectsTemp);
  }, [projects]);

  const handleClose = () => {
    setForm({
      name: "",
      id_card_number: "",
      email: "",
      role: "",
      phone_number: "",
      password: "",
      image_url: "",
      commission_disbursement: 0,
      disbursement_account_id: "",
      disbursement_service: "pulsapro",
    });
    setAlert({ show: false, message: "", type: "" });
    region.resetRegion();
    onClose(false);
  };

  const onChangeImg = (type, image_url) => {
    setForm({ ...form, [type]: image_url });
  };

  const handleChangeProject = (selected) => {
    setSelectedProjects([...selected]);
  };

  const submitData = (event) => {
    event.preventDefault();
    let formData = { ...form, address_id: String(region.city), roles: [] };
    formData.roles = [
      {
        id: roleOpt[formData.role],
      },
    ];

    if (selectedProjects.length > 0) {
      formData.projects = [];
      selectedProjects.forEach((project) => {
        formData.projects.push({
          id: project.value,
        });
      });
    }

    createTechnician(formData);
  };

  const createTechnician = async (form) => {
    setLoading(true);
    try {
      let response = await registerTechnician({ ...form, address_id: String(region.city) });
      onSuccess(true, response.data.message, response.data.data);
      handleClose();
    } catch (error) {
      let message = "";
      if (error.response) {
        let { data } = error.response;
        message = data.message;
      } else {
        message = "Something Error Happened";
      }

      setAlert({ show: true, message, type: "danger" });
    }

    setLoading(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()}>
      <form onSubmit={(event) => submitData(event)}>
        <Modal.Header closeButton>
          <Modal.Title>Tambah Teknisi</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {alert.show && (
            <div className={`alert alert-${alert.type} alert-dismissible fade show`} role="alert">
              {alert.message}
              <button
                type="button"
                className="close"
                onClick={() => setAlert({ show: false, message: "", type: "" })}
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
          )}

          <div className="form-group">
            <label htmlFor="">
              Nama Teknisi <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.name}
              onChange={(event) => setForm({ ...form, name: event.target.value })}
            />
          </div>

          <div className="form-group">
            <label htmlFor="EmailForm">
              Alamat Email <RequiredAsterisk />
            </label>
            <input
              type="email"
              id="EmailForm"
              className="form-control"
              required
              value={form.email}
              onChange={(event) => setForm({ ...form, email: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">Password</label>
            <input
              type="text"
              className="form-control"
              value={form.password}
              onChange={(event) => setForm({ ...form, password: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Nomor Telepon <RequiredAsterisk />
            </label>
            <input
              type="number"
              className="form-control"
              required
              value={form.phone_number}
              onChange={(event) => setForm({ ...form, phone_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Role <RequiredAsterisk />
            </label>
            <select
              className="form-control"
              required
              value={form.role}
              onChange={(event) => setForm({ ...form, role: event.target.value })}
            >
              <option value="" disabled>
                --- Pilih Role ---
              </option>
              <option value="technician">TECHNICIAN</option>
              <option value="helper">HELPER</option>
            </select>
          </div>
          <div className="form-group mb-4">
            <label>Foto Akun</label>
            <ImageUploader image={form.image_url} type="image_url" onChangeImg={onChangeImg} />
          </div>
          <div className="form-group">
            <label htmlFor="project">Kelompok/Project</label>
            <Select
              isMulti
              placeholder="Kelompok / Project"
              onChange={(selected) => handleChangeProject(selected)}
              options={projectOpt}
            />
          </div>
          <div className="form-group">
            <label htmlFor="provinsi">
              Provinsi <RequiredAsterisk />
            </label>
            <select
              name="provinsi"
              id="provinsi"
              className="form-control"
              value={region.province}
              onChange={(event) => region.setProvince(event.target.value)}
              required
            >
              <option value="">-- Pilih Provinsi --</option>
              {region.provinces.map((province, index) => (
                <option key={index} value={province.province}>
                  {province.province}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="kabupaten">
              Area Kabupaten/Kota <RequiredAsterisk />
            </label>
            <select
              name="kabupaten"
              id="kabupaten"
              className="form-control"
              value={region.city}
              onChange={(event) => region.setCity(event.target.value)}
              required
            >
              <option value="">-- Pilih Kabupaten/Kota --</option>
              {region.cities.map((city, index) => (
                <option key={index} value={city.id}>
                  {city.kota_kab}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="">
              Nomor KTP Teknisi <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.id_card_number}
              onChange={(event) => setForm({ ...form, id_card_number: event.target.value })}
            />
          </div>
          <div className="form-group">
            <label htmlFor="">
              Nama KTP <RequiredAsterisk />
            </label>
            <input
              type="text"
              className="form-control"
              required
              value={form.id_card_name}
              onChange={(event) => setForm({ ...form, id_card_name: event.target.value })}
            />
          </div>
          <div className="form-group mb-4">
            <label>
              Foto KTP Teknisi <RequiredAsterisk />
            </label>
            <ConfidentialImageUploader
              image={form.id_card_image_url}
              type="id_card_image_url"
              onChangeImg={(type, image_url) => {
                setForm({ ...form, [type]: image_url });
              }}
              required={true}
            />
          </div>
          <div className="row form-group">
            <div className="col-auto">Pencairan komisi dengan aplikasi Pulsa Pro</div>
            <div className="col-auto">
              <Toggle
                checked={form.commission_disbursement === 1}
                onChange={(event) => {
                  if (event.target.checked) {
                    setForm({ ...form, commission_disbursement: 1 });
                  } else {
                    setForm({ ...form, commission_disbursement: 0 });
                  }
                }}
              />
            </div>
          </div>
          <div className="form-group">
            <label htmlFor="">Akun Pulsa Pro</label>
            <input
              type="number"
              className="form-control"
              value={form.disbursement_account_id}
              disabled={form.commission_disbursement === 0}
              onChange={(event) =>
                setForm({ ...form, disbursement_account_id: event.target.value })
              }
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
            Close
          </button>
          <button type="submit" className="btn btn-primary" disabled={loading}>
            <i className="fas fa-plus"></i> Buat Teknisi
          </button>
        </Modal.Footer>
      </form>
    </Modal>
  );
}
