import { Modal } from "react-bootstrap";
import { useTechnicianHooks } from "../../utils/TechniciansUtils";

export default function ModalDetailTechnician({ show, id, onClose }) {
  let { data, error } = useTechnicianHooks({ id });

  const handleClose = () => {
    onClose(false);
  };

  return (
    <Modal show={show} onHide={() => handleClose()} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>Data Teknisi</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {error && <div className="alert alert-danger">{error}</div>}
        <h5>Data Diri</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td style={{ width: "150px" }}>Nama</td>
              <td>{data.name}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Role/Jabatan</td>
              <td>
                {data.roles?.length === 0 && "-"}
                <ul className="px-3 mb-0">
                  {data.roles?.map((role, index) => (
                    <li key={index}>{role.name}</li>
                  ))}
                </ul>
              </td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>No. Teknisi</td>
              <td>{data.technician_detail?.technician_number || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Foto</td>
              <td>
                {data.image_url ? (
                  <img src={data.image_url} alt={data.name} style={{ maxWidth: "200px" }} />
                ) : (
                  "-"
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>No. HP</td>
              <td>{data.phone_number}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Email</td>
              <td>{data.email}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Provinsi</td>
              <td>{data.addresses?.province || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Kota</td>
              <td>{data.addresses?.kota_kab || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>No. KTP</td>
              <td>{data.id_card_number || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Nama KTP</td>
              <td>{data.id_card_name || "-"}</td>
            </tr>
            <tr>
              <td style={{ width: "150px" }}>Foto KTP</td>
              <td>
                {data.id_card_image_url ? (
                  <img
                    src={data.id_card_image_url}
                    alt={"Foto KTP " + data.name}
                    style={{ maxWidth: "400px" }}
                  />
                ) : (
                  "-"
                )}
              </td>
            </tr>
          </tbody>
        </table>

        <h5>Informasi Komisi</h5>
        <table className="table table-bordered">
          <tbody>
            <tr>
              <td style={{ width: "250px" }}>Komisi Ke Pulsa Pro</td>
              <td>
                {data.technician_detail?.commission_disbursement ? (
                  <span className="badge badge-success">Ya</span>
                ) : (
                  <span className="badge badge-danger">Tidak</span>
                )}
              </td>
            </tr>
            <tr>
              <td style={{ width: "250px" }}>Akun Pulsa Pro</td>
              <td>{data.disbursement_account_id || "-"}</td>
            </tr>
          </tbody>
        </table>
      </Modal.Body>
      <Modal.Footer>
        <button type="button" className="btn btn-secondary" onClick={() => handleClose()}>
          Close
        </button>
      </Modal.Footer>
    </Modal>
  );
}
