import { useState, useEffect } from "react";
import moment from "moment";
import Pagination from "../../components/Pagination";
import AlertBox from "../../components/AlertBox";
import Box from "../../components/Box";
import MainLayout from "../../components/layouts/MainLayout";
import { useAllTicketsHooks } from "../../utils/ticket/TicketUlits";
import ModalReportTicketing from "../../components/ticketing/ModalReportTicketing";
import ModalDownloadTickets from "../../components/ticketing/ModalDownloadTickets";
import { useGroupListHooks } from "../../utils/ProjectsUtilHooks";
import { useControlPermission } from "../../utils/ActionPermissionUtils";
import ModalReportFollowupTicketing from "../../components/ticketing/ModalReportFollowupTicketing";
import { ROLE_ASSIGN_TICKET } from "../../tools/tickettools";

export default function TicketingPage() {
  const { hasAccess } = useControlPermission();
  const { projects } = useGroupListHooks();

  const [formFilter, setFormFilter] = useState({
    q: "",
    role: "",
    type: "",
    status: "",
    assign_to: "",
    project_id: "",
  });
  const [filter, setFilter] = useState({
    q: "",
    role: "",
    status: "",
    project_id: "",
    assign_to: "",
    sort_by: "",
    sort_type: "ASC",
    page: 1,
    page_size: 10,
  });
  const [alert, setAlert] = useState({ show: false, message: "", type: "" });

  const [selectedId, setSelectedId] = useState("");
  const [showModalTicket, setShowModalTicket] = useState(false);
  const [showModalDownload, setShowModalDownload] = useState(false);
  const [showModalFollowUp, setShowModalFollowUp] = useState(false);

  const { data, fetchTickets, meta, loading } = useAllTicketsHooks({ filter });

  const toggleModalTicket = (id) => {
    setSelectedId(id);
    setShowModalTicket(true);
  };

  const changeSort = (field) => {
    let sortType = filter.sort_type === "ASC" ? "DESC" : "ASC";
    setFilter({ ...filter, ...formFilter, sort_by: field, sort_type: sortType, page: 1 });
  };

  const toggleModalFollowUp = (id) => {
    setSelectedId(id);
    setShowModalFollowUp(true);
  };

  useEffect(() => {
    fetchTickets(filter);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const gotoPage = (page) => {
    setFilter({ ...filter, page: page });
  };

  const toggleModalDownload = () => {
    setShowModalDownload(!showModalDownload);
  };

  const onSuccess = (message) => {
    fetchTickets(filter);
    setAlert({ show: true, message: message, type: "success" });
  };

  function applyFilter() {
    let form_filter = { ...filter, ...formFilter, page: 1 };
    setFilter(form_filter);
  }

  const resetFilter = () => {
    setFormFilter({ q: "", role: "", status: "", project_id: "", type: "", assign_to: "" });
    setFilter({
      q: "",
      role: "",
      type: "",
      status: "",
      project_id: "",
      assign_to: "",
      sort_by: "",
      sort_type: "ASC",
      page: 1,
      page_size: 10,
    });
  };

  const changeFilter = (name, value) => {
    setFormFilter({ ...formFilter, [name]: value });
  };

  return (
    <MainLayout resource_key="ticket">
      <div className="container-fluid">
        <div className="row mb-4">
          <div className="col">
            <h1 className="h3 mb-0 text-gray-800">Tiket Bantuan</h1>
          </div>
          <div className="col-auto">
            {hasAccess("export-ticket") && (
              <button className="btn btn-primary" onClick={() => toggleModalDownload()}>
                <i className="fas fa-download"></i> Unduh Data Tiket
              </button>
            )}
          </div>
        </div>

        <Box title="Daftar Tiket" loading={loading}>
          <div className="mb-4">
            <div className="row">
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Pencarian</label>
                  <input
                    type="text"
                    className="form-control"
                    value={formFilter.q}
                    onChange={(event) => changeFilter("q", event.target.value)}
                    placeholder="No. Registrasi / No. Tiket / Nama / No. Hape"
                  />
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="status">Status</label>
                  <select
                    type="text"
                    name="status"
                    value={formFilter.status}
                    onChange={(event) => changeFilter("status", event.target.value)}
                    className="form-control"
                    id="status"
                  >
                    <option value="">Default</option>
                    <option value="open">Open</option>
                    <option value="working">Working</option>
                    <option value="closed">Closed</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label>Penugasan Ke</label>
                  <select
                    value={formFilter.assign_to}
                    onChange={(event) => changeFilter("assign_to", event.target.value)}
                    className="form-control"
                    id="assign_to"
                  >
                    <option value="">Semua</option>
                    {Object.keys(ROLE_ASSIGN_TICKET).map((key, index) => (
                      <option value={key} key={index}>
                        {ROLE_ASSIGN_TICKET[key]}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="role">Tipe</label>
                  <select
                    name="type"
                    value={formFilter.type}
                    onChange={(event) => changeFilter("type", event.target.value)}
                    className="form-control"
                    id="type"
                  >
                    <option value="">Semua</option>
                    <option value="follow-up">Follow Up</option>
                    <option value="technical">Technical</option>
                    <option value="marketing">Marketing</option>
                    <option value="billing">Billing</option>
                  </select>
                </div>
              </div>
              <div className="col-12 col-md-2">
                <div className="form-group">
                  <label htmlFor="projectFilter">Grup/Project</label>
                  <select
                    className="form-control"
                    id="projectFilter"
                    value={formFilter.project_id}
                    onChange={(event) => changeFilter("project_id", event.target.value)}
                  >
                    <option value="">SEMUA</option>
                    {projects.map((project, index) => (
                      <option value={project.id} key={index}>
                        {project.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="col-auto">
                <div className="form-group">
                  <button
                    className="btn btn-primary"
                    onClick={() => applyFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Filter
                  </button>
                  <button
                    className="btn btn-default"
                    onClick={() => resetFilter()}
                    style={{ marginTop: "32px" }}
                  >
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </div>

          <AlertBox {...alert} setAlert={setAlert} />
          <div style={{ overflowX: "auto" }}>
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th>No. Tiket</th>
                  <th>Tanggal Masuk</th>
                  <th className="text-nowrap" onClick={() => changeSort("total_hours")}>
                    Durasi
                    <span className="pl-4 text-primary">
                      {filter.sort_type === "ASC" ? (
                        <i className="fa-solid fa-arrow-down-short-wide"></i>
                      ) : (
                        <i className="fa-solid fa-arrow-up-short-wide"></i>
                      )}
                    </span>
                  </th>
                  <th>Nama</th>
                  <th>Alamat</th>
                  <th>Project</th>
                  <th>Telepon</th>
                  <th>Tipe</th>
                  <th>Status</th>
                  <th>Prioritas</th>
                  <th>Tugaskan ke</th>
                </tr>
              </thead>
              <tbody>
                {data.length === 0 && (
                  <tr>
                    <td colSpan={12} className="text-center">
                      Tidak ada data
                    </td>
                  </tr>
                )}
                {data.map((ticket, index) => (
                  <tr key={index}>
                    <td>
                      {ticket.type === "follow-up" ? (
                        <button
                          className="btn btn-block text-nowrap btn-sm btn-custom-orange"
                          onClick={() => toggleModalFollowUp(ticket.id)}
                        >
                          <i className="fas fa-file-pen"></i>{" "}
                          {ticket.status === "closed" ? "Detail" : "Update"} Follow Up
                        </button>
                      ) : (
                        <button
                          className="btn btn-block text-nowrap btn-sm btn-primary"
                          onClick={() => toggleModalTicket(ticket.id)}
                        >
                          <i className="fas fa-ticket-alt"></i>{" "}
                          {ticket.status === "closed" ? "Detail" : "Update"} Tiket
                        </button>
                      )}
                    </td>
                    <td>{ticket.ticket_number}</td>
                    <td>{moment.unix(ticket.createdAt).format("DD MMMM YYYY - HH:mm")}</td>
                    <td>
                      <AlertDuration total_hours={ticket.total_hours} />
                      {ticket.total_time}
                    </td>
                    <td>{ticket.name}</td>
                    <td>
                      {ticket.customer.installation_address.address +
                        " RT " +
                        ticket.customer.installation_address.rt +
                        "/RW " +
                        ticket.customer.installation_address.rw +
                        ", " +
                        ticket.customer.installation_address.addresses.kelurahan +
                        ", " +
                        ticket.customer.installation_address.addresses.kecamatan +
                        ", " +
                        ticket.customer.installation_address.addresses.kota_kab +
                        ", " +
                        ticket.customer.installation_address.addresses.province +
                        ", " +
                        ticket.customer.installation_address.addresses.postal_code}
                    </td>
                    <td>{ticket.customer.project?.name || "-"}</td>
                    <td>{ticket.phone_number}</td>
                    <td>{ticket.type}</td>
                    <td>
                      {ticket.status === "open" && (
                        <span className="badge badge-success">open</span>
                      )}
                      {ticket.status === "working" && (
                        <span className="badge badge-warning">working</span>
                      )}
                      {ticket.status === "closed" && (
                        <span className="badge badge-secondary">closed</span>
                      )}
                    </td>
                    <td>
                      {ticket.priority === "low" && <span className="badge badge-info">Low</span>}
                      {ticket.priority === "medium" && (
                        <span className="badge badge-warning">Medium</span>
                      )}
                      {ticket.priority === "high" && (
                        <span className="badge badge-danger">High</span>
                      )}
                    </td>
                    <td>{ticket.role}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {meta.count > meta.page_size && (
            <Pagination
              totalpage={Math.ceil(meta.count / meta.page_size)}
              selectedpage={meta.page}
              clickpage={(page) => gotoPage(page)}
              next={() => gotoPage(meta.page + 1)}
              prev={() => gotoPage(meta.page - 1)}
            />
          )}
        </Box>
      </div>

      <ModalReportTicketing
        show={showModalTicket}
        id={showModalTicket ? selectedId : ""}
        onClose={() => setShowModalTicket(false)}
        onSuccess={onSuccess}
      />
      <ModalDownloadTickets
        show={showModalDownload}
        onClose={() => setShowModalDownload(false)}
        onSuccess={onSuccess}
      />
      <ModalReportFollowupTicketing
        show={showModalFollowUp}
        id={showModalFollowUp ? selectedId : ""}
        onClose={() => setShowModalFollowUp(false)}
        onSuccess={onSuccess}
      />
    </MainLayout>
  );
}

function AlertDuration({ total_hours }) {
  if (total_hours > 168)
    return (
      <span className="badge badge-pill badge-danger mr-1">
        <i className="fa fa-exclamation"></i>
      </span>
    );

  if (total_hours > 72)
    return (
      <span
        className="badge badge-pill badge-orange mr-1"
        style={{ backgroundColor: "#F28C28", color: "#ffffff" }}
      >
        <i className="fa fa-exclamation"></i>
      </span>
    );

  if (total_hours > 24)
    return (
      <span className="badge badge-pill badge-warning mr-1">
        <i className="fa fa-exclamation"></i>
      </span>
    );

  return null;
}
